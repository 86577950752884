
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function vehicleWheelDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', {
        'className': 'cm_vehicle-wheel-visualization',
        'id': 'cm_vehicle-wheel-visualization'
    }), _createElement('div', { 'className': 'cm_vehicle-error' }, 'Failed to visualize the vehicle'), _createElement('div', { 'className': 'cm_wheel-error' }, 'Failed to visualize the wheel')));
}
        export const componentNames = []