import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';
import {
  getVehicleString,
  updateVehicleVisualization,
  setStoreConfig,
} from '../_auto-sync/autosyncVisualizer.js';

const ignoreFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetPanel')?.ignoreFields;

const { baseFields, extraFields } = bigcommerceSparkDefaults.fitmentSearch;

const baseUpdateCallback = bigcommerceSparkDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

let searchResultsVehicleVisualizer = null;

function updateCallback() {
  baseUpdateCallback?.();

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  searchResultsVehicleVisualizer = updateVehicleVisualization({
    autoSyncVisualizer: searchResultsVehicleVisualizer,
    autoSyncConfig: {
      elId: 'cm_vehicle-visualization',
      height: '100px',
      vehicleImageSize: 640,
      vehicleAngles: [32],
      showColorPicker: false,
    },
    vehicleString,
  });
}

const baseInitFunc = bigcommerceSparkDefaults.InitFunc;

const InitFunc = () => {
  baseInitFunc();
  setStoreConfig('jce-racing', [...baseFields, ...extraFields]);
};

const onVehicleDiscarded = () => {
  [...window.document.body.classList]
    .filter((cl) => cl.startsWith('cm_') && (cl.endsWith('-visualized') || cl.endsWith('-failed')))
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });
};

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  InitFunc,
  fitmentSearch: {
    ...bigcommerceSparkDefaults.fitmentSearch,
    onVehicleDiscarded,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) => !['SearchResult', 'CategoryFacetPanel'].includes(w.name),
    ),
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: {
        firstChildOf: ['page-type-category', 'page-type-brand']
          .map((className) => `body.${className} #faceted-search-container`)
          .join(),
        class: 'cm_facet-panel_container',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'WheelTireVehicleInfo',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'VehicleWheelDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'VehicleWheelDialog',
    },
    {
      name: 'VehicleWheelDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/vehicleWheelDialog',
    },
  ],
};
